import { LicenseBasic } from '@services';
import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { IconCloudDownload, IconTrash } from '@tabler/icons';
import { Wait } from '@components';

const AppTile = (props: {
    app: LicenseBasic;
    showInstallButton?: boolean;
    showUninstallButton?: boolean;
    onInstall?: (app: LicenseBasic) => void;
    onUninstall?: (app: LicenseBasic) => void;
}) => {
    const [installing, setInstalling] = React.useState(false);

    const handleInstall = () => {
        if (props.onInstall) {
            setInstalling(true);
            setTimeout(() => {
                setInstalling(false);
                props.onInstall(props.app);
            }, 500);
        }
    };

    const onUninstall = () => {
        if (props.onUninstall) {
            setInstalling(true);
            setTimeout(() => {
                setInstalling(false);
                props.onUninstall(props.app);
            }, 500);
        }
    };

    return (
        <Card sx={{ width: 200, height: 200, border: '1px solid', margin: '2px', background: '#EEE' }}>
            <CardContent sx={{ height: '60%' }}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h3" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {props.app.name}
                    </Typography>
                </div>

                <Typography>{props.app.description}</Typography>
            </CardContent>

            <CardActions sx={{ flexDirection: 'row-reverse' }}>
                {!installing && props.showInstallButton && (
                    <IconButton color="primary" size="small" onClick={handleInstall}>
                        <IconCloudDownload />
                    </IconButton>
                )}

                {!installing && props.showUninstallButton && (
                    <IconButton color="secondary" size="small" onClick={onUninstall}>
                        <IconTrash />
                    </IconButton>
                )}

                <Wait loading={installing} />
            </CardActions>
        </Card>
    );
};

export const AppPanel = (props: {
    showInstallButton?: boolean;
    showUninstallButton?: boolean;
    apps: LicenseBasic[];
    onInstall?: (app: LicenseBasic) => void;
    onUninstall?: (app: LicenseBasic) => void;
}) => {
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
            {props.apps.map((app) => (
                <AppTile
                    key={app.id}
                    app={app}
                    showUninstallButton={props.showUninstallButton}
                    showInstallButton={props.showInstallButton}
                    onInstall={props.onInstall}
                    onUninstall={props.onUninstall}
                />
            ))}
        </div>
    );
};
