interface GeneralCategoryGeneralNotification {
    text: string;
};


export enum Category {
    General = 'General',
}

export type PushNotificationMap = Record<
    Category.General,
    GeneralCategoryGeneralNotification
>;

export type Message<T extends Category> = PushNotificationMap[T];