import React from 'react';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Button, Divider, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import { timeSince, getIcon } from '@utils';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

export interface Notification {
    id: string;
    title: string;
    description?: string;
    time: Date;
    status: 'new' | 'seen' | 'read';
    icon?: string;
    actions?: {
        primary?: string;
        secondary?: string;
    };
    onClick?: (id: string) => void;
    onActionClick?: (id: string, actionName: string) => void;
}

const NotificationItem = ({ id, title, description, time, status, icon, actions, onClick, onActionClick }: Notification) => {
    const handlePrimaryActionClick = React.useCallback(() => {
        if (onActionClick) {
            onActionClick(id, actions?.primary);
        }
    }, [onActionClick, id, actions?.primary]);

    const handleSecondaryActionClick = React.useCallback(() => {
        if (onActionClick) {
            onActionClick(id, actions?.secondary);
        }
    }, [onActionClick, id, actions?.secondary]);

    const handleClick = React.useCallback(() => {
        if (onClick) {
            onClick(id);
        }
    }, [onClick, id]);

    const Icon = getIcon(icon);
    return (
        <ListItemWrapper sx={{ minWidth: '20rem' }} onClick={handleClick}>
            <ListItem alignItems="center">
                <ListItemAvatar>
                    <Icon />
                </ListItemAvatar>
                <ListItemText primary={title} />
                {time && (
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    {timeSince(time)} ago
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
            <Grid container direction="column" className="list-container">
                <Grid item xs={12} sx={{ pb: 2 }}>
                    <Typography variant="subtitle2">{description}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        {actions?.primary && (
                            <Grid item>
                                <Button
                                    style={{ marginLeft: '5px' }}
                                    variant="contained"
                                    color="primary"
                                    onClick={handlePrimaryActionClick}
                                >
                                    {actions.primary}
                                </Button>
                            </Grid>
                        )}
                        {actions?.secondary && (
                            <Grid item>
                                <Button
                                    style={{ marginLeft: '5px' }}
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSecondaryActionClick}
                                >
                                    {actions.secondary}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </ListItemWrapper>
    );
};
// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NO_NOTIFICATIONS = [
    {
        id: 'no-notification-item',
        title: 'No notifications',
        description: 'You have no notifications',
        time: new Date(),
        status: 'read',
        icon: 'IconHourglassEmpty'
    }
];
const NotificationList = (props: { notifications: Notification[] }) => {
    const theme = useTheme();
    const notifications =
        props.notifications.length > 0
            ? props.notifications
            : NO_NOTIFICATIONS;

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {notifications.map((notification) => (
                <>
                    <NotificationItem
                        id={notification.id}
                        key={notification.id}
                        title={notification.title}
                        description={notification.description}
                        time={notification.time}
                        status={notification.status}
                        icon={notification.icon}
                        actions={notification.actions}
                        onClick={notification.onClick}
                        onActionClick={notification.onActionClick}
                    />
                    <Divider />
                </>
            ))}
        </List>
    );
};

export default NotificationList;
