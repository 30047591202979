import React, { useState } from 'react';
import { explisoftService } from '@services';
import { Category, Message } from "@shared";

export const usePushNotifications = <C extends Category, M extends Message<C>>(category: C) => {
    const [notifications, setNotifications] = useState<{ message: M }[]>([]);

    React.useEffect(() => {
        explisoftService.subscribeToPushNotifications(category, (notification: M) => {
            setNotifications((notifications) => {
                return [...notifications, { message: notification }];
            });
        });
    }, [category]);

    return { notifications };
}