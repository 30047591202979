import React from 'react';
import { BackButton, MainCard, Wait, MusicSelector, AiButton } from '@components';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { useVideoProjectDetails } from 'src/hooks/use-video-project-details';
import { ContentCard } from './components/content-card';
import { Button, Box } from '@mui/material';
import { ProjectContent } from '@services';
import Slider from '@mui/material/Slider';
import { AgentType, LLMResponse } from "@shared";

export const DisplayName = 'Video Project Details';

const toReadableTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${remainingSeconds}`;
}

export const App = () => {
    const routeParams = useParams();
    const projectId = get(routeParams, 'projectId');
    const [contentInErrorState, setContentInErrorState] = React.useState<string[]>([]);
    const [contentsLength, setContentsLength] = React.useState<{ [key: string]: number }>({});

    const { isLoading, updateProjectBackgroundMusic, contents, createContent, handleDelete, handleUpdate, generateVideo, project } = useVideoProjectDetails(projectId, true);

    const [selectedMusic, setSelectedMusic] = React.useState<string | null>(project?.backgroundMusicId ?? null);
    const onPressDelete = React.useCallback((contentId: string) => {
        handleDelete(contentId).then(() => {
            setContentInErrorState(state => state.filter(c => c !== contentId));
            setContentsLength(state => {
                const newState = { ...state };
                newState[contentId] = 0;
                return newState;
            });
        });
    }, [handleDelete]);

    const onContentUpdate = React.useCallback((contentId: string, content: string) => {
        setContentsLength(state => {
            const newState = { ...state };
            newState[contentId] = content.length;
            return newState;
        });
    }, []);

    const totalContentLength = React.useMemo(() => {
        const length = Object.values(contentsLength).reduce((acc, curr) => acc + curr, 0);
        const seconds = Math.ceil(length / 12.9);
        return toReadableTime(seconds);
    }, [contentsLength]);

    const openUrlInNewTab = React.useCallback(() => {
        window.open(`${project?.videoUrl}`, '_blank');
    }, [project?.videoUrl]);

    const downloadLogs = React.useCallback(() => {
        window.open(`${project?.logUrl}`, '_blank');
    }, [project?.logUrl]);

    const onErrorStateChange = React.useCallback((contentId: string, isError: boolean): void => {
        if (isError) {
            setContentInErrorState(state => [...state, contentId]);
        } else {
            setContentInErrorState(state => state.filter(c => c !== contentId));
        }
    }, []);

    const isActionDisabled = React.useMemo(() => {
        return isLoading || project?.generationStatus === 'IN_PROGRESS' || contents?.length === 0 || contentInErrorState.length > 0;
    }, [isLoading, project, contents, contentInErrorState]);

    const onMusicSelect = React.useCallback((musicId?: string) => {
        setSelectedMusic(musicId);
    }, []);

    const { backgroundMusicId, volume: currentVolume } = project || { backgroundMusicId: null, volume: 2 };
    React.useEffect(() => {
        setSelectedMusic(backgroundMusicId);
    }, [backgroundMusicId]);

    const [volume, setVolume] = React.useState<number>(2);
    React.useEffect(() => {
        setVolume(currentVolume);
    }, [currentVolume]);

    const handleVolumeChange = (_: Event, newValue: number | number[]) => {
        setVolume(newValue as number);
    };

    React.useEffect(() => {
        updateProjectBackgroundMusic(selectedMusic, volume);
    }, [selectedMusic, volume, updateProjectBackgroundMusic]);

    const handleLLMResponse = React.useCallback(async (response: LLMResponse) => {
        const data = response.data;
        if (!Array.isArray(data)) {
            return;
        }
        for (const content of data) {
            try {
                await createContent(content.text + '\n\n Image Text: ' + content.image);
            } catch (err) {
                console.error(err);
            }
        }
    }, [createContent]);

    return <MainCard title={project?.name || 'DisplayName'} secondary={<BackButton />}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <MusicSelector onSelect={onMusicSelect} selected={selectedMusic} />
                <Box style={{ width: '150px', display: 'flex', alignItems: 'center' }}>
                    <Slider aria-label="Volume" value={volume} onChange={handleVolumeChange} min={1} max={10} />
                    <div style={{ marginLeft: '15px' }}>{volume}</div>
                </Box>
                <AiButton agentType={AgentType.VIDEO_CONTENT_GENERATOR_AGENT} text={'content generator'} onAccept={handleLLMResponse} />
            </div>
            <div>Approx duration: {totalContentLength}</div>
            <div>
                {project?.videoUrl && <Button onClick={openUrlInNewTab}>Download Video</Button>}
                {project?.generationStatus !== "IN_PROGRESS" && <Button onClick={downloadLogs}>Download Logs</Button>}
                {project?.generationStatus === "FAILED" && <div>Failed to generate video. Please try again.</div>}
                <Button disabled={isActionDisabled} onClick={generateVideo}>Generate Video</Button>
                {project?.generationStatus === 'IN_PROGRESS' ? `Generating Video${project?.progressMessage || ''}...` : ''}
            </div>
        </div>
        {
            contents.sort((c1: ProjectContent, c2: ProjectContent) => c1.orderNo - c2.orderNo).map((content, index) => {
                return <ContentCard
                    key={content.textId}
                    content={content}
                    onDelete={onPressDelete}
                    onSave={handleUpdate}
                    onErrorStateChange={onErrorStateChange}
                    index={`${index + 1}/${contents.length}`}
                    onContentUpdate={onContentUpdate}
                />;
            })
        }
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button disabled={isLoading} onClick={() => createContent()}>Add New Content</Button>
        </div>
        <Wait loading={isLoading} />
    </MainCard>;
};

export const params = ['projectId'];
export const name = 'textImageToVideoDetails';
export const open = (projectId: string, queryParams?: {}) => {
    return `/sub-apps/textImageToVideoDetails/projectId/${projectId}`;
};
