const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
const host = window.location.host;
const wsUrl = `${protocol}//${host}`;

export class Socket {
    private ws: WebSocket;
    private static instance: Socket;
    private failedConnectionAttempts = 0;
    private subscriber: (data: any) => void;

    private constructor() {
        this.ws = new WebSocket(wsUrl);

        this.ws.onopen = () => {
            console.log('WebSocket connection established');
            this.failedConnectionAttempts = 0;
        }

        // Listen for messages from the server
        this.ws.onmessage = (event) => {
            console.log('Message from server:', event.data);
            if (this.subscriber) {
                this.subscriber(event.data);
            }
        };

        // Handle connection closure
        this.ws.onclose = (event) => {
            console.log(`WebSocket connection closed: Code=${event.code}, Reason=${event.reason}`);
            const delay = Math.min(10000, Math.pow(2, this.failedConnectionAttempts) * 1000);
            this.failedConnectionAttempts++;
            setTimeout(() => {
                this.ws = new WebSocket(wsUrl);
            }, delay);
        };
    }

    public static getInstance(): Socket {
        if (!this.instance) {
            this.instance = new Socket();
        }
        return this.instance;
    }

    public subscribe(subscriber: (message: string) => void) {
        this.subscriber = subscriber;
    }
}