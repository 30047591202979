import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { ServiceContext } from '@controllers';
import { MyInfo } from '@services';

const image = require('./../assets/logos/transparent.png');

const Logo = () => {
    const theme = useTheme();
    const { authController } = useContext(ServiceContext);
    const [me, setMe] = useState<MyInfo>();

    useEffect(() => {
        authController?.me().then((myInfo) => {
            setMe(myInfo);
        }).catch(() => {
            setMe({ logoUrl: image as string } as any);
        });
    }, [authController]);

    const imgRef = useRef<HTMLImageElement>(null);
    const onImageError = () => {
        if (imgRef.current) {
            imgRef.current.src = image;
        }
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img ref={imgRef} src={me?.logoUrl} alt={me?.domainName || 'Explisoft'} style={{ maxHeight: '60px', maxWidth: '190px' }} onError={onImageError} />
        </div>
    );
};

export default Logo;
