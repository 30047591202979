import React from 'react';
import { explisoftService } from "@services";
import { AgentType, LLMResponse, LLMRequest, Conversation } from '@shared';
import { Dialog, Typography, IconButton, DialogTitle, DialogContent, DialogActions, TextField, Box } from '@mui/material';
import { IconSend, IconX, IconCheck } from "@tabler/icons";

export interface IChatPopupProps {
    agentType: AgentType;
    text?: string;
    open: boolean;
    onClose: () => void;
    onAccept: (response: LLMResponse) => void;
}

export const ChatPopup = (prop: IChatPopupProps) => {
    const { agentType, open } = prop;
    const [message, setMessage] = React.useState('');
    const [conversation, setConversation] = React.useState<Conversation[]>([]);
    const [response, setResponse] = React.useState<LLMResponse | null>(null);
    const [error, setError] = React.useState<string | null>(null);
    const contentRef = React.useRef(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (open) {
            setConversation([]);
            setResponse(null);
            setMessage('');
            setError(null);
        }
    }, [open]);

    React.useEffect(() => {
        if (contentRef.current) {
            // Scroll to the bottom of the content
            contentRef.current.scrollTop = contentRef.current.scrollHeight;
        }
    }, [conversation]);

    const canUserSend = React.useMemo(() => {
        const n = conversation.length;
        if (n === 0) {
            return true;
        }
        return conversation[n - 1].role !== 'user'
    }, [conversation]);

    const handleSend = React.useCallback(() => {
        setConversation(existingConversation => {
            return [
                ...existingConversation,
                {
                    role: 'user',
                    parts: [{ text: message }]
                }
            ]
        })
    }, [message]);

    React.useEffect(() => {
        const n = conversation.length;
        if (n === 0 || conversation[n - 1].role !== 'user' || !message) {
            return;
        }
        const request: LLMRequest = {
            agentType,
            message,
            conversationHistory: conversation
        }
        setError(null);
        setMessage('');
        setLoading(true);
        explisoftService.send(request)
            .then((response: LLMResponse) => {
                setResponse(response);
                setConversation(existingConversation => {
                    return [
                        ...existingConversation,
                        {
                            role: 'model',
                            parts: [{ text: response.data as string }]
                        }
                    ]
                })
            }).catch((error) => {
                console.error(error);
                const last = conversation[n - 1].parts[0].text;
                setResponse(null);
                setConversation(existingConversation => {
                    return existingConversation.slice(0, n - 2);
                });
                setMessage(last as string);
                setError('Unable to get response from ExpliGenie');
            }).finally(() => {
                setLoading(false);
            });
    }, [conversation, message, agentType]);

    const handleClose = React.useCallback((_, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
        }
        prop.onClose();
    }, [prop]);

    const handleOnAccept = React.useCallback(() => {
        if (response) {
            prop.onAccept(response);
        }
    }, [response, prop]);

    return (
        <Dialog fullWidth maxWidth={'sm'} open={prop.open} onClose={handleClose} sx={{
            "& .MuiPaper-root": {
                height: "80%", // Set height to 80% of the viewport
                maxHeight: "90%", // Optional: Set a maximum height
                width: "70%", // Optional: Set width as percentage
                maxWidth: "80%", // Optional: Set maximum width
            },
        }} disableEscapeKeyDown>
            <DialogTitle>
                <h2>ExpliGenie {prop.text}</h2>
            </DialogTitle>
            <IconButton onClick={prop.onClose} sx={{
                position: 'absolute',
                right: 8,
                top: 8
            }}>
                <IconX />
            </IconButton>
            <DialogContent sx={{ overflowY: "auto" }} ref={contentRef}>
                <div>
                    <div>
                        <div>
                            {conversation.map((part, index) => {
                                const textAlign = part.role === 'user' ? 'right' : 'left';
                                return (
                                    <div key={index} style={{ textAlign, marginTop: '10px', backgroundColor: part.role === 'user' ? '#f0f0f0' : '#e0e0e0', padding: '10px', borderRadius: '10px' }}>
                                        <Typography variant="h6" color="textSecondary">
                                            {part.role === 'user' ? 'You' : 'ExpliGenie'}
                                        </Typography>
                                        <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                            {part.parts.map((p, i) => {
                                                return (
                                                    <Typography key={i} color="textPrimary">
                                                        <pre>
                                                            {typeof p.text === "string" ? p.text : JSON.stringify(p.text, null, 2)}
                                                        </pre>
                                                    </Typography>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>

            </DialogContent>
            <Typography variant="caption" color="error" style={{ padding: '10px' }}>
                {error}
            </Typography>

            <DialogActions sx={{ marginTop: '0px' }}>
                <TextField
                    style={{ padding: '7px', marginTop: '0px' }}
                    value={message}
                    label="type your message"
                    variant="outlined"
                    fullWidth
                    required
                    multiline
                    rows={2}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <IconButton onClick={handleSend} disabled={!canUserSend || loading || !message}>
                    <IconSend />
                </IconButton>

                <IconButton onClick={handleOnAccept} disabled={!response}>
                    <IconCheck />
                </IconButton>
            </DialogActions>
        </Dialog>
    )
}