import { authController } from '@controllers';
import { cache, Key } from '@services';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const useUserDetails = <T>(key: Key) => {
    const [userDetails, setUserDetails] = React.useState<T>();

    React.useEffect(() => {
        const handleUserInfoChange = () => {
            setUserDetails(cache.get<T>(key));
        };
        cache.addListener(key, handleUserInfoChange);
        handleUserInfoChange();
    }, [key]);
    return { value: userDetails };
};

export const useLogout = () => {
    const navigate = useNavigate();
    const logout = () => {
        authController.logout();
        setTimeout(() => {
            navigate('/auth/login');
        }, 200);
    }
    return { logout };
}