import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { Loadable } from '@components';
import MinimalLayout from '../layout/MinimalLayout';

const LoginContainer = Loadable(lazy(() => import('../views/authentication/authentication/LoginContainer')));

// ==============================|| Login ROUTING ||============================== //

const AuthenticationRoutes = (isLoggedIn = false, params = '', tenantId: string) => {
    let element = <MinimalLayout />;
    if (isLoggedIn) {
        if (!tenantId) {
            element = <Navigate to="/auth/no-tenant" />;
        } else {
            element = <Navigate to={`/?${params}`} />;
        }
    }

    return {
        path: '/',
        element,
        children: [
            {
                path: '/auth/login',
                element: <LoginContainer />
            }
        ]
    };
};

export default AuthenticationRoutes;
