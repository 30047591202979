export interface LLMResponse {
    data: string | object;
    validJsonSchema: boolean;
    tokenConsumed: number;
}

export interface Conversation {
    role: 'user' | 'model';
    parts: {
        text: string | object;
    }[];
}

export interface ConversationString {
    role: 'user' | 'model';
    parts: {
        text: string;
    }[];
}

export enum AgentType {
    VIDEO_CONTENT_GENERATOR_AGENT = "VIDEO_CONTENT_GENERATOR_AGENT",
}

export const getAgentType = (agentType: string): AgentType => {
    switch (agentType) {
        case "VIDEO_CONTENT_GENERATOR_AGENT":
            return AgentType.VIDEO_CONTENT_GENERATOR_AGENT;
        default:
            throw new Error("Invalid agent type");
    }
}

export interface LLMRequest {
    agentType: AgentType;
    message: string;
    conversationHistory?: Conversation[];
}