import React from 'react';
import { IconButton } from '@mui/material';
import { IconRobot } from "@tabler/icons";
import { PRIMARY_COLOR, AgentType, LLMResponse } from '@shared';
import { usePermission } from '@hooks';
import { ChatPopup } from "./chat-popup";

export interface IAiButtonProps {
    agentType: AgentType;
    text?: string;
    onAccept: (response: LLMResponse) => void;
}

export const AiButton = (props: IAiButtonProps) => {
    const { isAIAllowed } = usePermission();
    const [chatPopupOpen, setChatPopupOpen] = React.useState(false);

    const handleChatPopupOpen = () => {
        setChatPopupOpen(true);
    };

    const handleChatPopupClose = () => {
        setChatPopupOpen(false);
    }

    const handleAccept = (response: LLMResponse) => {
        props.onAccept(response);
        setChatPopupOpen(false);
    }

    if (!isAIAllowed) return null;

    return (
        <>
            <ChatPopup agentType={props.agentType} open={chatPopupOpen} onClose={handleChatPopupClose} text={props.text} onAccept={handleAccept} />
            <IconButton aria-label="bot icon" onClick={handleChatPopupOpen}>
                <IconRobot size={24} color={PRIMARY_COLOR} />
            </IconButton>
        </>
    )
}