import * as React from 'react';
import { explisoftService, User } from '@services';
import { IBasePickerProps, BasePicker } from '../base-picker';

const getLabel = (option: User) => `${option.firstName} ${option.lastName} (${option.emailId})`;
const getData = (query?: string) => explisoftService.getFilteredUser(query).catch(() => []);
export type IPeoplePickerProps = Pick<
    IBasePickerProps<User>,
    Exclude<keyof IBasePickerProps<User>, 'getLabel' | 'getData' | 'getObjectId'>
>;

export default function PeoplePicker({
    multiple = false,
    id = '',
    key,
    style = {},
    onSelect,
    label,
    initalValue = []
}: IPeoplePickerProps) {
    return (
        <BasePicker<User>
            multiple={multiple}
            id={id}
            key={key}
            style={style}
            onSelect={onSelect}
            label={label}
            initalValue={initalValue}
            getLabel={getLabel}
            getData={getData}
        />
    );
}
