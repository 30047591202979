import React from 'react';
import { LicenseBasic, explisoftService, error as errorToast } from '@services';

export const useAppManagement = () => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');
    const [availableToInstall, setAvailableToInstall] = React.useState<LicenseBasic[]>([]);
    const [installedApp, setInstalledApp] = React.useState<LicenseBasic[]>([]);

    const installApp = (licenseId: string) => {
        setIsLoading(true);
        setError('');
        return explisoftService
            .installApp(licenseId)
            .then(() => {
                setTimeout(() => {
                    fetchAvailableToInstall();
                }, 500);
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
                throw err;
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const uninstallApp = (licenseId: string) => {
        setIsLoading(true);
        setError('');
        return explisoftService
            .uninstallApp(licenseId)
            .then(() => {
                setTimeout(() => {
                    fetchAvailableToInstall();
                }, 500);
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
                throw err;
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const fetchAvailableToInstall = React.useCallback(async () => {
        setIsLoading(true);
        setError('');
        setAvailableToInstall([]);
        const availableToInstallPromise = explisoftService
            .availableToInstall()
            .then((res) => {
                setAvailableToInstall(res);
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
            });
        const installedAppPromise = explisoftService
            .installedApps()
            .then((res) => {
                setInstalledApp(res);
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
            });
        await Promise.all([availableToInstallPromise, installedAppPromise]);
        setIsLoading(false);
    }, []);

    React.useEffect(() => {
        fetchAvailableToInstall();
    }, [fetchAvailableToInstall]);

    return { availableToInstall, error, loading: isLoading, installApp, uninstallApp, installedApp };
};
