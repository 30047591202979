import { explisoftService, EmailValidationResult, RegisterUserRequest, ApiGenericResponse, MyInfo, cache, Key, AuthToken } from '@services';
import { UserLicense } from '@services';
import { getCookie, setCookie, removeCookie } from 'typescript-cookie';
import { AuthProvider } from '@shared';

const AUTH_TOKEN_COOKIE = 'x-auth-token';
const AUTH_TOKEN_COOKIE_EXPIRY = 'x-auth-token-expiry';
const AUTH_TOKEN_TENANT_ID = 'x-auth-tenant-id';
const FIVE_DAYS = 5 * 24 * 60 * 60 * 1000;

export interface Policy {
    isOwner: boolean;
    licenseAndScope: UserLicense;
    isPortalUser: boolean;
    isPortalAdmin: boolean;
}

export interface AuthController {
    social_media_signin(authProvider: AuthProvider, credential: string): Promise<string>;
    isAuthenticated(): { authenticated: boolean; tenantId: string };
    login(emailId: string, password: string): Promise<string>;
    logout(): boolean;
    validateEmail(email: string): Promise<EmailValidationResult>;
    generateOTP(email: string): Promise<{ otpId: string }>;
    registerUser(registerUserRequest: RegisterUserRequest): Promise<ApiGenericResponse>;
    me(noCache?: boolean): Promise<MyInfo>;
    forgotPassword(emailId: string): Promise<{ message: string }>;
    resetPassword(username: string, token: string): Promise<{ message: string }>;
    switchTenant(tenantId: string): Promise<void>;
    saveTokenCookie(authToken: AuthToken): void;
}

export class AuthControllerImpl implements AuthController {
    constructor() {
        console.log('Intializing AuthControllerImpl...');
    }

    public async social_media_signin(authProvider: AuthProvider, credential: string): Promise<string> {
        const authToken = await explisoftService.social_media_signin(authProvider, credential);
        this.saveTokenCookie(authToken);
        return authToken.tenantId;
    }

    public async switchTenant(tenantId: string): Promise<void> {
        const authToken = await explisoftService.switchTenant(tenantId);
        this.saveTokenCookie(authToken);
    }

    public isAuthenticated(): { authenticated: boolean; tenantId: string } {
        const authToken = getCookie(AUTH_TOKEN_COOKIE);

        setTimeout(async () => {
            const authTokenExpiry = parseInt(getCookie(AUTH_TOKEN_COOKIE_EXPIRY) || '0');
            if (!authToken || !authTokenExpiry) {
                return;
            }

            const ttl = authTokenExpiry - new Date().getTime();
            if (ttl < FIVE_DAYS) {
                const token = await explisoftService.renewToken();
                this.saveTokenCookie(token);
            }
        }, 0);
        return { authenticated: !!authToken, tenantId: getCookie(AUTH_TOKEN_TENANT_ID) };
    }
    async login(emailId: string, password: string): Promise<string> {
        const authToken = await explisoftService.login(emailId, password);
        this.saveTokenCookie(authToken);
        return authToken.tenantId;
    }

    public saveTokenCookie(authToken: AuthToken) {
        setCookie(AUTH_TOKEN_COOKIE, authToken.token, { expires: 30, path: '/' });
        setCookie(AUTH_TOKEN_COOKIE_EXPIRY, new Date(authToken.expiry).getTime(), { expires: 30, path: '/' });
        setCookie(AUTH_TOKEN_TENANT_ID, authToken.tenantId || '', { expires: 30, path: '/' });
    }

    async validateEmail(email: string): Promise<EmailValidationResult> {
        return await explisoftService.validateEmail(email);
    }

    async generateOTP(email: string): Promise<{ otpId: string }> {
        return await explisoftService.generateOTP(email);
    }

    async registerUser(registerUserRequest: RegisterUserRequest): Promise<ApiGenericResponse> {
        return await explisoftService.registerUser(registerUserRequest);
    }

    async me(noCache: boolean = false): Promise<MyInfo> {
        if (!this.isAuthenticated().authenticated) {
            throw new Error('Not authenticated');
        }
        if (!this.isAuthenticated().tenantId) {
            throw new Error('Tenant not found');
        }
        let myInfo: MyInfo;
        if (noCache) {
            myInfo = await explisoftService.me();
        } else {
            myInfo = cache.get(Key.USER_INFO);
            if (!myInfo) {
                myInfo = await explisoftService.me();
            }
        }
        cache.put(Key.USER_INFO, myInfo);
        cache.put<Policy>(Key.POLICY, {
            isOwner: !!myInfo.isOwner,
            licenseAndScope: myInfo.licenseAndScope,
            isPortalUser: !!myInfo.isPortalUser,
            isPortalAdmin: !!myInfo.isPortalAdmin
        });
        return myInfo;
    }

    async forgotPassword(emailId: string): Promise<{ message: string }> {
        return await explisoftService.forgotPassword(emailId);
    }

    async resetPassword(username: string, token: string): Promise<{ message: string }> {
        return await explisoftService.resetPassword(username, token);
    }

    logout(): boolean {
        removeCookie(AUTH_TOKEN_COOKIE, { path: '/' });
        removeCookie(AUTH_TOKEN_COOKIE_EXPIRY, { path: '/' });
        removeCookie(AUTH_TOKEN_TENANT_ID, { path: '/' });
        cache.flush();
        return true;
    }
}
