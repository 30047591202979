import React, { lazy } from 'react';
// project imports
import MainLayout from '../layout/MainLayout';
import { Loadable } from '@components';

const RegisterContainer = Loadable(lazy(() => import('../views/authentication/authentication/RegisterContainer')));
const ConfirmLogin = Loadable(lazy(() => import('../views/authentication/authentication/ConfirmLogin')));
const LogoutThenLogin = Loadable(lazy(() => import('../views/authentication/authentication/LogoutThenLogin')));
const ForgotPasswordContainer = Loadable(lazy(() => import('../views/authentication/authentication/ForgotPasswordContainer')));
const ResetPasswordContainer = Loadable(lazy(() => import('../views/authentication/authentication/ResetPasswordContainer')));
const SignupWithAuthGroup = Loadable(lazy(() => import('../views/authentication/authentication/SignupWithAuthGroupContainer')));
const NoTenantContainer = Loadable(lazy(() => import('../views/authentication/authentication/NoTenantContainer')));

// ==============================|| MAIN ROUTING ||============================== //

const OtherAuthenticationRoutes = () => ({
    path: '/auth',
    element: <MainLayout complete={false} />,
    children: [
        {
            path: '/auth/confirm-login',
            element: <ConfirmLogin />
        },
        {
            path: '/auth/register',
            element: <RegisterContainer />
        },
        {
            path: '/auth/reset-password',
            element: <ResetPasswordContainer />
        },
        {
            path: '/auth/forgot-password',
            element: <ForgotPasswordContainer />
        },
        {
            path: '/auth/group-signup/:groupId',
            element: <SignupWithAuthGroup />
        },
        {
            path: '/auth/no-tenant',
            element: <NoTenantContainer />
        }
    ]
});

export default OtherAuthenticationRoutes;
