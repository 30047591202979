const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '/',
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12
};

export const GoogleSiginConfig = {
    clientId: '935368494575-gj300drqvpjofl4cdc1h4e6781m444be.apps.googleusercontent.com'
};

export default config;
