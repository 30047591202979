import React, { useState } from 'react';
import { explisoftService, error as errorToast } from '@services';
import { authController } from '@controllers';
import { useNavigate } from 'react-router-dom';

export const usePersonnelWorkspace = () => {
    const navigate = useNavigate();

    const [canCreateWorkspace, setCanCreateWorkspace] = useState<boolean>(false);
    React.useEffect(() => {
        explisoftService
            .canCreatePersonnelWorkspace()
            .then((workspace) => {
                setCanCreateWorkspace(workspace.allowed);
            })
            .catch((error) => {
                setCanCreateWorkspace(false);
                errorToast(error.message || 'Error fetching personnel workspace');
            });
    }, []);

    const [createWorkspaceLoading, setCreateWorkspaceLoading] = useState<boolean>(false);
    const createWorkspace = async (name: string) => {
        setCreateWorkspaceLoading(true);
        return explisoftService.createPersonnelWorkspace(name).then((authToken) => {
            if (authToken.tenantId) {
                authController.saveTokenCookie(authToken);
                navigate('/');
                window.location.reload();
            }
            setCreateWorkspaceLoading(false);
        }).catch((error) => {
            setCreateWorkspaceLoading(false);
            errorToast(error.message || 'Error creating personnel workspace');
        });
    }

    return {
        canCreateWorkspace,
        createWorkspace,
        createWorkspaceLoading
    }
}