import { Key, Policy } from '@services';
import { useUserDetails } from '@hooks';

export const usePermission = () => {
    const { value: policy } = useUserDetails<Policy>(Key.POLICY);

    // assign and remove license [portal admin only]
    const isAllowedAssignLicense =
        policy?.isPortalAdmin || (policy?.isPortalUser && policy?.licenseAndScope['tenant_management']?.includes('assign_license'));
    const isAllowedRemoveLicense =
        policy?.isPortalAdmin || (policy?.isPortalUser && policy?.licenseAndScope['tenant_management']?.includes('remove_license'));
    const isEnableTenantAllowed =
        policy?.isPortalAdmin || (policy?.isPortalUser && policy?.licenseAndScope['tenant_management']?.includes('enable_tenant'));
    const isDisableTenantAllowed =
        policy?.isPortalAdmin || (policy?.isPortalUser && policy?.licenseAndScope['tenant_management']?.includes('disable_tenant'));

    // user license and scope
    const isAllowedAddUserInOrganization = policy?.isOwner || policy?.licenseAndScope['user_management']?.includes('create_user');
    const isAllowedFindUserInOrganization = policy?.isOwner || policy?.licenseAndScope['user_management']?.includes('find_user');
    const isAllowedUpdateUserInOrganization = policy?.isOwner || policy?.licenseAndScope['user_management']?.includes('update_user');

    const isAddCustomerAccountAllowed = policy?.licenseAndScope['accounts']?.includes('create_customer');
    const isUpdateCustomerAccountAllowed = policy?.licenseAndScope['accounts']?.includes('update_customer');
    const isFindCustomerAccountAllowed = policy?.licenseAndScope['accounts']?.includes('find_customer');

    const isAddSupplierAccountAllowed = policy?.licenseAndScope['accounts']?.includes('create_supplier');
    const isUpdateSupplierAccountAllowed = policy?.licenseAndScope['accounts']?.includes('update_supplier');
    const isFindSupplierAccountAllowed = policy?.licenseAndScope['accounts']?.includes('find_supplier');

    const isProductsTabeAllowed = policy?.licenseAndScope['inventory']?.includes('get_items');
    const isServicesTabAllowed = policy?.licenseAndScope['inventory']?.includes('get_services');
    const isCreateItemAllowed = policy?.licenseAndScope['inventory']?.includes('create_item');
    const isUpdateItemAllowed = policy?.licenseAndScope['inventory']?.includes('update_item');
    const isCreateServiceAllowed = policy?.licenseAndScope['inventory']?.includes('create_service');
    const isUpdateServiceAllowed = policy?.licenseAndScope['inventory']?.includes('update_service');
    const isUpdateStockAllowed = policy?.licenseAndScope['inventory']?.includes('update_stock');
    const isViewStockAllowed = policy?.licenseAndScope['inventory']?.includes('view_stock');
    const isReceiveSupplyAllowed = policy?.licenseAndScope['inventory']?.includes('receive_supply');
    const isViewPurchaseReceiptAllowed = policy?.licenseAndScope['inventory']?.includes('view_purchase_receipt');

    const isReadConfigAllowed = policy?.licenseAndScope['configManagement']?.includes('read_config');
    const isUpdateConfigAllowed = policy?.licenseAndScope['configManagement']?.includes('update_config');

    const isPlaceOrderAllowed = policy?.licenseAndScope['sales']?.includes('create_order');
    const isGetOrderAllowed = policy?.licenseAndScope['sales']?.includes('get_order');
    const isManageEMIAllowed = policy?.licenseAndScope['sales']?.includes('manage_emi');

    const isAIAllowed = policy?.licenseAndScope['ExpliGenie']?.includes('ai');

    return {
        isAllowedAddUserInOrganization,
        isAllowedFindUserInOrganization,
        isAllowedUpdateUserInOrganization,

        isAllowedAssignLicense,
        isAllowedRemoveLicense,
        isEnableTenantAllowed,
        isDisableTenantAllowed,

        isAddCustomerAccountAllowed,
        isUpdateCustomerAccountAllowed,
        isFindCustomerAccountAllowed,
        isCustomerTabAllowed: isAddCustomerAccountAllowed || isUpdateCustomerAccountAllowed || isFindCustomerAccountAllowed,

        isAddSupplierAccountAllowed,
        isUpdateSupplierAccountAllowed,
        isFindSupplierAccountAllowed,
        isSupplierTabAllowed: isAddSupplierAccountAllowed || isUpdateSupplierAccountAllowed || isFindSupplierAccountAllowed,

        isProductsTabeAllowed,
        isServicesTabAllowed,
        isCreateItemAllowed,
        isUpdateItemAllowed,
        isCreateServiceAllowed,
        isUpdateServiceAllowed,
        isUpdateStockAllowed,
        isViewStockAllowed,
        isReceiveSupplyAllowed,
        isViewPurchaseReceiptAllowed,

        isReadConfigAllowed,
        isUpdateConfigAllowed,

        isPlaceOrderAllowed,
        isGetOrderAllowed,
        isManageEMIAllowed,

        isAIAllowed
    };
};
